import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { FileUploader } from "react-drag-drop-files";
import { LinearProgress } from '@mui/material';



const MUIModal = ({ open, handleClose }) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };



    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                style={{ border: "0px" }}
            >

                <Box sx={style}>
                    {
                        <>
                            <p style={{ textAlign: "center", marginBottom: "15px" }}>Uploading...</p>
                            <LinearProgress />
                        </>
                    }
                </Box>

            </Modal>
        </>
    );
};

export default MUIModal;