import { Button, Skeleton } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useRef, useState } from 'react';
import Style from './HomeStyle.module.css'
import { db, storage } from '../firebase.init';
import MUIModal from '../Components/MUIModal';
import { useEffect } from 'react';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { v4 } from 'uuid';
import { addDoc, collection, deleteDoc, doc, onSnapshot, setDoc } from 'firebase/firestore';
import { motion, useDragControls } from "framer-motion"
import { Box } from '@mui/system';


const Home = () => {


    const [open, setOpen] = useState(false);
    const fileTypes = ["JPG", "JPEG", "PNG", "GIF", "MP3", "MP4"];
    const [file, setFile] = useState(null);
    const [deleteButton, setDeleteButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);
    const [imgList, setImgList] = useState([]);
    const [videoList, setVideoList] = useState([]);
    const [audioList, setAudioList] = useState([]);
    const [position, setPositionList] = useState([]);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const constraintsRef = useRef(null);

    const dragControls = useDragControls()



    const uploadToStorage = (file) => {
        setLoading(true)
        const fileRef = ref(storage, `files/${file[0]?.name + v4()}`)
        uploadBytes(fileRef, file[0]).then(async (res) => {
            getDownloadURL(ref(storage, res.metadata.fullPath))
                .then(async (url) => {
                    await addDoc(collection(db, "images"), {
                        fileLink: url,
                    });
                    setLoading(false)
                    setOpen(false)
                })
        })
    }

    const uploadVideo = (file) => {
        setLoading(true)
        const fileRef = ref(storage, `files/${file[0]?.name + v4()}`)
        uploadBytes(fileRef, file[0]).then(async (res) => {
            getDownloadURL(ref(storage, res.metadata.fullPath))
                .then(async (url) => {
                    await addDoc(collection(db, "videos"), {
                        fileLink: url,
                    });
                    setLoading(false)
                    setOpen(false)
                })
        })
    }

    const uploadAudio = (file) => {
        setLoading(true)
        const fileRef = ref(storage, `files/${file[0]?.name + v4()}`)
        uploadBytes(fileRef, file[0]).then(async (res) => {
            getDownloadURL(ref(storage, res.metadata.fullPath))
                .then(async (url) => {
                    await addDoc(collection(db, "audios"), {
                        fileLink: url,
                    });
                    setLoading(false)
                    setOpen(false)
                })
        })
    }

    const handleDeleteButton = async (id, collection) => {
        if (window.confirm('Delete')) {
            await deleteDoc(doc(db, collection, id));
        }

    }

    const handelUploadChange = (file) => {
        setFile(file);
        const fileType = file[0].type.split('/')[0]
        if (file) {
            if (file[0].size / (1024 * 1024) > 100) {
                window.alert("Upload files below 100 mb")
            } else {
                if (fileType !== "audio" && fileType !== "video") {
                    uploadToStorage(file)
                }
                else if (fileType === "video") {
                    uploadVideo(file)
                }
                else if (fileType === "audio") {
                    uploadAudio(file)
                }
            }
        }
    };



    useEffect(() => {
        setFileLoading(true)
        return onSnapshot(collection(db, "images"), (snapshot) => {
            setImgList(snapshot.docs.map((doc) => [{ ...doc.data(), "id": doc.id }]))
            setFileLoading(false)
        })
    }, [])

    useEffect(() => {
        setFileLoading(true)
        return onSnapshot(collection(db, "videos"), (snapshot) => {
            setVideoList(snapshot.docs.map((doc) => [{ ...doc.data(), "id": doc.id }]))
            setFileLoading(false)
        })
    }, [])
    useEffect(() => {
        setFileLoading(true)
        return onSnapshot(collection(db, "audios"), (snapshot) => {
            setAudioList(snapshot.docs.map((doc) => [{ ...doc.data(), "id": doc.id }]))
            setFileLoading(false)
        })
    }, [])
    useEffect(() => {
        setFileLoading(true)
        return onSnapshot(collection(db, "position"), (snapshot) => {
            setPositionList(snapshot.docs.map((doc) => [{ ...doc.data(), "id": doc.id }]))
            setFileLoading(false)
        })
    }, [])

    const finalPosition = async (event, info, id) => {
        const temp = position.find((data) => data[0]?.id === id)
        if (temp) {
            let x = temp[0].x + info.offset.x;
            let y = temp[0].y + info.offset.y;

            await setDoc(doc(db, "position", id), {
                x: x,
                y: y
            });
        } else {
            await setDoc(doc(db, "position", id), {
                x: info.offset.x,
                y: info.offset.y
            });
        }


    }

    const getCordinates = (id) => {
        const temp = position.find((data) => data[0]?.id === id)
        if (temp) {
            let x = temp[0].x
            let y = temp[0].y
            let cordinate = { x, y }
            return cordinate
        }
        else {
            let cordinate = { x: 0, y: 0 }
            return cordinate
        }
    }



    return (
        <div className={Style.wrapper} ref={constraintsRef}>
            <div className={Style.buttonWrapper}>
                <button onClick={() => setDeleteButton(!deleteButton)} className={Style.deleteButton}>
                    <DeleteIcon className={Style.uploadIcon} /> Delete
                </button>
                <label style={{ margin: "10px" }} for="file-upload" class={Style.customFileUpload}>
                    <FileUploadIcon className={Style.uploadIcon} /> Upload
                </label>
                <input onChange={(e) => handelUploadChange(e.target.files)} id="file-upload" type="file" />
            </div>
            {
                fileLoading ?
                    <div className={Style.skeleton}>
                        {
                            [1, 2, 3, 4, 5, 6, 7, 8, 9].map((skel) => {
                                return (
                                    <Box>
                                        <Skeleton variant="rounded" width={210} height={300} style={{ margin: "0px 20px" }} />
                                    </Box>

                                )
                            })
                        }
                    </div>
                    :
                    <>
                        <div className={Style.contentWrapper} >
                            {
                                imgList.length !== 0 || videoList.length !== 0 || audioList.length !== 0 ?
                                    <>
                                        {
                                            imgList?.map(data => {
                                                const newCordinate = getCordinates(data[0]?.id)
                                                return (
                                                    <motion.div
                                                        drag
                                                        dragMomentum={false}
                                                        dragConstraints={constraintsRef}
                                                        initial={newCordinate}
                                                        onDragEnd={(event, info) => {
                                                            finalPosition(event, info, data[0]?.id)
                                                        }}
                                                        className={Style.imgCntainer}>

                                                        <img
                                                            onPointerDown={(e) => {
                                                                dragControls.start(e)
                                                            }}
                                                            className={Style.file} src={data[0]?.fileLink} alt="img" />



                                                        {
                                                            deleteButton && <CancelIcon onClick={() => handleDeleteButton(data[0]?.id, "images")} className={Style.closeIcon} />
                                                        }
                                                    </motion.div>
                                                )
                                            })
                                        }
                                        {
                                            videoList?.map(data => {
                                                const newCordinate = getCordinates(data[0]?.id)
                                                return (
                                                    <>
                                                        <motion.div
                                                            drag
                                                            dragMomentum={false}
                                                            dragControls={dragControls}
                                                            dragConstraints={constraintsRef}
                                                            initial={newCordinate}
                                                            onDragEnd={(event, info) => {
                                                                finalPosition(event, info, data[0]?.id)
                                                            }}
                                                            className={Style.videoContainer}>
                                                            <div className={Style.dragable}
                                                                onPointerDown={(e) => {
                                                                    dragControls.start(e)
                                                                }}
                                                            ></div>
                                                            <video className={Style.video} autoPlay loop controls>
                                                                <source src={data[0]?.fileLink} type="video/mp4" />
                                                            </video>
                                                            {
                                                                deleteButton && <CancelIcon onClick={() => handleDeleteButton(data[0]?.id, "videos")} className={Style.closeIcon} />
                                                            }
                                                        </motion.div>
                                                    </>
                                                )
                                            })
                                        }
                                        {
                                            audioList.map(data => {
                                                const newCordinate = getCordinates(data[0]?.id)
                                                return (
                                                    <motion.div
                                                        drag
                                                        dragMomentum={false}
                                                        dragControls={dragControls}
                                                        dragConstraints={constraintsRef}
                                                        onDragEnd={(event, info) => {
                                                            finalPosition(event, info, data[0]?.id)
                                                        }}
                                                        initial={newCordinate}

                                                        className={Style.audioContainer}>
                                                        <div className={Style.dragable}
                                                            onPointerDown={(e) => {
                                                                dragControls.start(e)
                                                            }}
                                                        ></div>
                                                        <audio
                                                            className={Style.audio}
                                                            autoPlay
                                                            loop
                                                            controls src={data[0]?.fileLink} />
                                                        {
                                                            deleteButton && <CancelIcon onClick={() => handleDeleteButton(data[0]?.id, "audios")} className={Style.closeIcon} />
                                                        }
                                                    </motion.div>
                                                )
                                            })
                                        }
                                    </>
                                    :
                                    " "
                            }
                        </div>
                    </>
            }
            {
                loading && <MUIModal
                    open={true}
                    handleClose={handleClose}
                />
            }
        </div>
    );
};

export default Home;